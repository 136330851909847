import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

const customersMonitorMenu = "customers_menu";

@Module
export default class CustomersMenuModule extends VuexModule {
  customers_menu = JSON.parse(
    localStorage.getItem(customersMonitorMenu) ?? "[]"
  );

  /**
   * Get theme mode OBJECT
   * @returns object | null
   */
  get getCustomersMenu(): object | null {
    return this.customers_menu;
  }

  @Mutation
  [Mutations.SET_CUSTOMERS_MENU_MUTATION](payload) {
    this.customers_menu = payload;
  }

  @Action
  [Actions.SET_CUSTOMERS_MENU_ACTION](payload) {
    localStorage.setItem(customersMonitorMenu, JSON.stringify(payload));
    this.context.commit(Mutations.SET_CUSTOMERS_MENU_MUTATION, payload);
  }
}
